@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Kantumruy";
    src: url("../public/font/Kantumruy-Bold.ttf");
}

@font-face {
    font-family: "KantumruyRegular";
    src: url("../public/font/Kantumruy-Regular.ttf");
}

@font-face {
    font-family: "Bayon";
    src: url("../public/font/Bayon.ttf");
}

.font-Kantumruy-bold {
    font-family: Kantumruy !important;
}

.font-Kantumruy-regular {
    font-family: KantumruyRegular !important;
}

.font-Bayon {
    font-family: Bayon !important;
}

.color-pink {
    color: #e22577 !important;
}

.color-blue {
    color: #1b80be !important;
}